<template>
  <div>

    <section id="dashboard" class="mt-4">
      <div class="container">
        <div class="row">
          <SideNav v-bind:selected='2' />
          
          <div class="col-xs-12 col-sm-12 col-md-12 mt-md-0">
            <div v-if="listings && listings.length > 0" class="row">
              <CompetitionListing v-for="listing in listings" v-bind:listing="listing" v-bind:key="listing.id"/>
            </div>
            <div v-else class="text-center" style="padding-top:30px;">
              <h3>You have not entered any competitions yet.</h3>
              <router-link v-bind:to="'/'"><button type="button" class="btn btn-custom mt-3 w-25">Enter Now</button></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer />
</template>

<script>
import Api from '../../scripts/api'

import SideNav from '../../components/dashboard/SideNavigator'
import CompetitionListing from '../../components/home/CompetitionListing'
import Footer from '../../components/global/Footer'


export default {
  components: {
    SideNav,
    CompetitionListing,
    Footer
  },
  props: {
    account: Object
  },
  data() {
    return {
      listings: []
    }
  },
  async created() {
      if(this.account == undefined || this.account.account == undefined) 
        // User isn't logged in, bring user to login page.
        return this.$router.push('/login');

      var result = await Api.competition.listUserWon();
      if(result.status == 200) {
        this.listings = await result.json();
      }
    }
};
</script>

